<template>
    <section class="motivation-section">
        <div class="container">
            Štěstí lze najít i v nejtemnějších obdobích života, pokud si člověk vzpomene na to, že může rozsvítit světlo.
        </div>
    </section>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

.motivation-section{
    background: #8946E9;
    padding: 150px 0;
    
    text-align: center;

    font-weight: 700;
    color: #fff;

    .container{
        font-size: 40px;
    }
}


@media only screen and (max-width: 1300px){
    .motivation-section{
        .container{
            font-size: 30px;
        }
    }
}
</style>