<template>
    <section class="references-section">
        <div class="container">
            <div class="card" v-for="(reference, i) in references" :key="i">
                <img src="https://picsum.photos/200/300" alt="" class="profile-img">
                <h3 class="card-title">{{ reference.title }}</h3>
                <span class="card-subtitle">{{ reference.subtitle }}</span>
                <i class="fa-solid fa-quote-left"></i>
                <p>{{ reference.content }}</p>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                    references: [
                    {
                        img: "https://picsum.photos/200/300",
                        title: "Milan Vorel",
                        subtitle: "Ředitel SŠAI",
                        content: "Práce se mi líbila a udělala dojem i na zahraniční kolegy."
                    },
                    {
                        img: "https://picsum.photos/200/300",
                        title: "Jakub Jelen",
                        subtitle: "CEO Monaxa Professionals s.r.o.",
                        content: "Vždy byla rychlá odezva a cítím bezpečnost a neobávám se nedodržení termínů."
                    },
                    {
                        img: "https://picsum.photos/200/300",
                        title: "Marek Novák",
                        subtitle: "CEO Hodinky teprve",
                        content: "Práce byla vždy kvalitní a na čas. Jednoduše je zde spoleh."
                    },
                ]
            }
        },
    }
</script>

<style lang="scss" scoped>

.references-section{
    padding: 50px 0; 

    .container{
        max-width: 1200px;

        display: flex;
        justify-content: space-between;
        gap: 20px;

        .card{
            background: #fff;
            box-shadow: 15px 15px 35px rgba(96, 96, 96, 0.35);
            padding: 20px;
            border-radius: 15px;

            width: 100%;

            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;

            text-align: center;

            .profile-img{
                border-radius: 50%;
                width: 160px;
                height: 160px;
            }

            .card-title{
                font-size: 30px;
                font-weight: 700;
            }

            .card-subtitle{
                font-weight: 600;
            }

            i{
                font-size: 70px;
                color: #EDEDED;

                animation: color-highlight 10s infinite;

            }

            &:nth-child(2) i{ animation-delay: 3s; }

            &:nth-child(3) i{ animation-delay: 6s; }

            @keyframes color-highlight{
                0%{
                    color: #EDEDED;
                }
            
                10%{
                    color: #712BEC;
                }

                30%{
                    color: #712BEC;
                }

                40%{
                    color: #EDEDED;
                }

                100%{
                    color: #EDEDED;
                }
            }
        }
    }
}

@media only screen and (max-width: 900px){
    .references-section{
        .container{
            max-width: 700px;

            flex-direction: column;

            .card{
                background: #fff;
                box-shadow: 15px 15px 35px rgba(96, 96, 96, 0.35);
                padding: 20px;
                border-radius: 15px;

                width: 100%;

                display: flex;
                flex-direction: column;
                gap: 15px;
                align-items: center;

                text-align: center;

                .profile-img{
                    border-radius: 50%;
                    width: 160px;
                    height: 160px;
                }

                .card-title{
                    font-size: 30px;
                    font-weight: 700;
                }

                .card-subtitle{
                    font-weight: 600;
                }

                i{
                    font-size: 70px;
                    color: #EDEDED;

                    animation: color-highlight 10s infinite;

                }

                &:nth-child(2) i{ animation-delay: 3s; }

                &:nth-child(3) i{ animation-delay: 6s; }

                @keyframes color-highlight{
                    0%{
                        color: #EDEDED;
                    }
                
                    10%{
                        color: #712BEC;
                    }

                    30%{
                        color: #712BEC;
                    }

                    40%{
                        color: #EDEDED;
                    }

                    100%{
                        color: #EDEDED;
                    }
                }
            }
        }
    }
}

</style>