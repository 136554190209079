<template>
    <section class=" text-half-image contact-page" id="contact-section">
        <flash-message :type="flashMessageType" :message="flashMessage" />

        <div class="container">
            <div class="col contact-info">
                <div class="header">
                    <h3>Kontaktujte nás</h3>
                    <span>Vyplňte formulář nebo nám pošlete email.</span>
                </div>

                <div class="content">
                    <a class="item" v-for="(item, index) in contactItems" :key="index" :href="item.link" target="_blank" :class="{ active: index === 3, hoverable: item.link }">
                        <div class="icon-wrapper">
                            <i :class="item.icon"></i>
                        </div>
                        <span class="label" v-html="item.text"></span>
                    </a>
                </div>
            </div>

            <div class="col contact-form">
                <Form @submit="sendForm" v-slot="{ errors }" class="form-tag" :class="{ 'form-sended-once': formSendedOnce }">
                    <div class="half-split">
                        <div class="input-group">
                            <label>Jméno</label>
                            <Field name="firstName" :rules="[isRequired, minTwoLength, maxTenLength]" max="10" v-model="firstName" />
                            <span v-show="errors.firstName" class="error-message">{{ errors.firstName }}</span>
                        </div>
    
                        <div class="input-group">
                            <label>Příjmení</label>
                            <Field name="lastName" :rules="[isRequired, minTwoLength, maxTenLength]" v-model="lastName" />
                            <span v-show="errors.lastName" class="error-message">{{ errors.lastName }}</span>
                        </div>
                    </div>

                    <div class="input-group">
                        <label>Email</label>
                        <Field name="email" :rules="[isRequired, minTwoLength, isValidEmail]" v-model="email" />
                        <span v-show="errors.email" class="error-message">{{ errors.email }}</span>
                    </div>

                    <div class="input-group">
                        <label>Volba <router-link to="/whats-next" target="_blank">(Zjistit nabídku)</router-link></label>

                        <div class="input-button-switch">
                            <input type="button" value="Mám zájem o služby" :class="{ active: option == 'interested' }" @click="changeOption('interested')" />
                            <input type="button" value="Mám dotaz" :class="{ active: option == 'question' }" @click="changeOption('question')" />
                            <input type="button" value="Jiné" :class="{ active: option == 'other' }" @click="changeOption('other')" />
                        </div>
                    </div>

                    <div class="input-group">
                        <label>Zpráva</label>
                        <Field as="textarea" rows="10" name="message" v-model="message" :rules="[isRequired, minTenLength]"></Field>
                        <span v-show="errors.message" class="error-message">{{ errors.message }}</span>
                    </div>

                    <button class="btn btn-highlight submit-button" :class="{ 'disabled': Object.keys(errors).length > 0 || !firstName || !lastName || !email || !message || formSendedOnce }">Odeslat</button>
                </Form>
            </div>
        </div>
    </section>
</template>

<script>
    import FlashMessage from "../components/FlashMessage.vue";
    import { Form, Field } from "vee-validate";
    import emailjs from 'emailjs-com';

    export default {
        components: { FlashMessage, Form, Field },
        data() {
            return {
                firstName: "",
                lastName: "",
                email: "",
                option: "interested",
                message: "",

                // eslint-disable-next-line no-undef
                emailjs_api_key: process.env.VUE_APP_EMAILJS_API_KEY,

                flashMessage: "",
                flashMessageType: "",

                formSendedOnce: false,

                contactItems: [
                    {
                        icon: "fa-solid fa-envelope",
                        text: "info@fitpixels.cz",
                        link: "mailto:info@fitpixels.cz"
                    },
                    {
                        icon: "fa-solid fa-phone",
                        text: "+420 704 338 431",
                        link: "tel:704338431"
                    },
                    {
                        icon: "fa-solid fa-location-dot",
                        text: "Ladova&nbsp;895, Mnichovice 251&nbsp;64"
                    },
                    {
                        icon: "fa-solid fa-calendar-days",
                        text: "Naplánovat si meeting",
                        link: "#"
                    },
                ]
            }
        },
        methods: {
            changeOption(option){
                this.option = option;
            },
            isRequired(value){
                return value ? true : "Toto pole je povinné.";
            },
            minTwoLength(value){
                return value.length > 2 ? true : "Položka musí být minimálně 3 znaky."
            },
            minTenLength(value){
                return value.length >= 10 ? true : "Položka musí být minimálně 10 znaků dlouhá."
            },
            maxTenLength(value){
                return value.length <= 10 ? true : "Položka musí být maximálně 10 znaků."
            },
            isValidEmail(value) {
                const emailPattern = /^[\w-.]+@[a-zA-Z]{2,}\.[a-zA-Z]{2,}$/;
                return emailPattern.test(value) ? true : "Email musí být ve správném formátu.";
            },
            async sendForm(){
                this.formSendedOnce = true;
                
                emailjs.send('service_ec1lg0b', 'template_q3cdobm', {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    option: this.option,
                    message: this.message
                }, this.emailjs_api_key)
                .then(response => {
                    console.log('SUCCESS!', response.status, response.text);

                    this.flashMessageType = "success";
                    this.flashMessage = "Formulář byl úspěšně odeslán.";

                }, error => {
                    console.log('FAILED...', error);
                    
                    this.flashMessageType = "danger";
                    this.flashMessage = "Formulář se nepodařilo odeslat. Kontaktujte nás na info@fitpixels.cz";

                    this.formSendedOnce = false;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>

.contact-page{
    padding: 50px 0;

    .container{
        display: flex;
        gap: 20px;
        align-items: stretch;
    }

    .contact-form{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .form-tag{
            display: flex;
            flex-direction: column;
            gap: 20px;

            position: relative;

            .half-split{
                width: 100%;

                display: flex;
                gap: 25px;
            }

            .input-group{
                display: flex;
                flex-direction: column;
                gap: 3px;
                width: 100%;

                label{
                    color: #727272;
                    font-weight: 600;
                }

                input, textarea{
                    padding: 15px 20px;
                    border-radius: 20px;
                    border: 2px solid rgba(128, 128, 128, 0.5);
                    box-shadow: 0 1px 2px rgba(64, 64, 64, 0.25);

                    background: #fff;
                    font-weight: 600;
                    color: #9B9B9B;
                    font-size: 15px;

                    font-family: inherit !important;

                    &:focus{
                        outline: none;
                    }
                }

                textarea{
                    resize: vertical;
                }

                a{
                    font-size: 15px;
                    color: inherit;
                }

                .input-button-switch{
                    display: flex;

                    input{
                        width: 100%;

                        border-radius: 0;
                        text-align: left;

                        &:first-child{
                            border-radius: 20px 0 0 20px;
                        }

                        &:last-child{
                            border-radius: 0 20px 20px 0;
                        }
                    }

                    .active{
                        border: 2px solid #712BEC;
                        color: #712BEC;
                        background: rgb(113, 43, 236, 0.1);
                    }
                }
            }

            .submit-button{
                transition: .3s;
                user-select: none;
            }

            .submit-button.disabled{
                background: lighten(#712BEC, 20%);
                pointer-events: none;
            }

            .error-message{
                color: #fa3852 !important;
                font-weight: 500;
            }
        }

        .form-sended-once{
            &::before{
                // content: "Děkujeme za vaši zprávu, ozveme se vám hned, jak to bude možné.";
                content: "";
                position: absolute;
                top: -10px;
                left: -10px;
                bottom: -10px;
                right: -10px;
                background: rgba(223, 223, 223, 0.473);
                border-radius: 20px;

                transition: 0.3s;

                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                font-size: 30px;
                font-weight: bold;

                padding: 20px;
            }
        }
    }

    .contact-info{
        width: 100%;
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 50px;

        .header{
            h3{
                font-size: 40px;
                font-weight: 600;
            }

            span{
                color: #000;
                font-weight: 600;
            }
        }

        .content{
            display: flex;
            flex-direction: column;
            gap: 5px;

            .item{
                padding: 20px;
                border-radius: 15px;

                display: flex;
                align-items: center;
                gap: 20px;
                text-decoration: none;

                transition: .3s;
                border: 3px solid transparent;

                .icon-wrapper{
                    width: 35px;
                    display: flex;
                    justify-content: center;

                    i{
                        font-size: 23px;
                        color: #5500E9;
                        transition: .3s;
                    }
                }

                .label{
                    color: #3A3A3A;
                    font-weight: 700;
                }

            }

            .hoverable:hover{
                border: 3px solid #712BEC;
                background: #F1EAFE;

                i{
                    transform: scale(1.3);
                }
            }
            
            .active{
                border: 3px solid #712BEC;
                background: #F1EAFE;
            }
        }
    }
}

@media only screen and (max-width: 1030px){
    .contact-page{
        .container{
            flex-direction: column;
            max-width: 700px;

            gap: 50px;
        }

        .contact-info{
            align-items: center;
            gap: 20px;

            .header{
                text-align: center;
            }

            .content{
                align-items: center;
            }
        }
    }
}

@media only screen and (max-width: 900px){
    .contact-page{
        .contact-form{
            padding: 0;

            form{
                .half-split{
                    gap: 20px;
                    flex-direction: row;
                }

                .input-button-switch{
                    flex-direction: row;

                    input{
                        border-radius: 0;

                        &:first-child{
                            border-radius: 20px 0 0 20px !important;
                        }

                        &:last-child{
                            border-radius: 0 20px 20px 0 !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 800px){
    
    .straight-way{
        .container{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .title{
            font-size: 30px;
        }

        .sub-title{
            font-size: 20px;
        }

        padding: 50px 0;

        .cols-wrapper{
            flex-direction: column;
            align-items: center;
            gap: 30px;

            .col{
                align-items: center;
                gap: 15px;

                .menu-title{
                    font-size: 18px;
                }
    
                span{
                    font-size: 14px;
    
                    a{
                        font-size: inherit;
                        color: inherit;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px){
    .contact-page{
        .contact-info{
            
            .header{
                text-align: center;
            }
            
            .content{
                align-items: stretch;
                width: 100%;
            }
        }
    }

    .contact-page{
        .contact-form{
            .form-header{
                .title{
                    font-size: 30px;
                }
            }

            form{
                .half-split{
                    gap: 20px;
                    flex-direction: column !important;
                }

                .input-button-switch{
                    flex-direction: column !important;

                    input{
                        border-radius: 0;

                        &:first-child{
                            border-radius: 20px 20px 0 0 !important;
                        }

                        &:last-child{
                            border-radius: 0 0 20px 20px !important;
                        }
                    }
                }
            }
        }
    }
}

</style>