<template>
    <section class="summary-section" id="summary-section">
        <div class="container">
            <div class="card">
                <div class="item">
                    <div class="item-title">Individualita</div>
                    <p>Každý klient je unikátní, proto cenu, trvání spolupráce a nastavení podmínek řešíme na první schůzi.</p>
                </div>

                <div class="divider"></div>
                
                <div class="item">
                    <div class="item-title">Čas</div>
                    <div class="open-hours-card">
                        <span class="days">Po - Pá</span>
                        <span class="time">16:00 - 20:00</span>

                        <div class="top-right-border"></div>
                        <div class="bottom-left-border"></div>
                    </div>
                    <div class="open-hours-card">
                        <span class="days">So</span>
                        <span class="time">8:00 - 18:00</span>

                        <div class="top-right-border"></div>
                        <div class="bottom-left-border"></div>
                    </div>
                </div>

                <div class="divider"></div>

                <div class="item">
                    <div class="item-title">Způsob</div>
                    <p>Kontaktovat nás můžete přes formulář nebo si rovnou naplánovat schůzi přes kalendář.</p>
                    <a href="#" class="btn btn-highlight btn-plan-meeting">Naplánovat meeting</a>
                    <a href="#" class="btn btn-secondary">Kontaktovat</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { gsap } from "gsap";
    import ScrollTrigger from "gsap/ScrollTrigger";
    import CSSPlugin from "gsap/CSSPlugin";

    export default {
        mounted(){
            gsap.registerPlugin(ScrollTrigger, CSSPlugin);

            // const topRightBorders = document.querySelectorAll(".top-right-border");
            const openHoursCards = document.querySelectorAll(".open-hours-card");
            openHoursCards.forEach(openHoursCard => {
                const topRightBorder = openHoursCard.querySelector(".top-right-border");
                const bottomLeftBorder = openHoursCard.querySelector(".bottom-left-border");

                // top and right border animation
                gsap.to(topRightBorder, {
                    scrollTrigger: {
                        trigger: openHoursCard,
                        start: "top bottom",
                        end: "top 80%",
                        scrub: true
                    },
                    width: "100%",
                });
                gsap.to(topRightBorder, {
                    scrollTrigger: {
                        trigger: openHoursCard,
                        start: "center 80%",
                        end: "center 60%",
                        scrub: true,
                    },
                    height: "100%",
                });

                // bottom and left border animation
                gsap.to(bottomLeftBorder, {
                    scrollTrigger: {
                        trigger: openHoursCard,
                        start: "center bottom",
                        end: "center 80%",
                        scrub: true,
                    },
                    height: "100%",
                });
                gsap.to(bottomLeftBorder, {
                    scrollTrigger: {
                        trigger: openHoursCard,
                        start: "center 80%",
                        end: "center 60%",
                        scrub: true,
                    },
                    width: "100%",
                });

                // add borde-raadius
                gsap.to(topRightBorder, {
                    scrollTrigger: {
                        trigger: openHoursCard,
                        start: "center 60%",
                        end: "center 50%",
                        scrub: true,
                    },
                    borderRadius: "0 15px 15px 15px",
                });
                gsap.to(bottomLeftBorder, {
                    scrollTrigger: {
                        trigger: openHoursCard,
                        start: "center 60%",
                        end: "center 50%",
                        scrub: true,
                    },
                    borderRadius: "0 15px 15px 15px",
                });
            });

            gsap.from(".btn-plan-meeting", {
                scrollTrigger: {
                    trigger: ".btn-plan-meeting",
                    start: "bottom bottom",
                    end: "bottom 50%",
                    scrub: true
                },
                background: "#767676"
            });

            // const bottomLeftBorders = document.querySelectorAll(".bottom-left-border");
            // bottomLeftBorders.forEach(bottomLeftBorder => {
            //     // bottom and left border animation
            //     gsap.to(bottomLeftBorder, {
            //         scrollTrigger: {
            //             trigger: ".open-hours-card",
            //             start: "center bottom",
            //             end: "center 80%",
            //             scrub: true,
            //         },
            //         height: "100%",
            //     });
            //     gsap.to(bottomLeftBorder, {
            //         scrollTrigger: {
            //             trigger: ".open-hours-card",
            //             start: "center 80%",
            //             end: "center 60%",
            //             scrub: true,
            //         },
            //         width: "100%",
            //     });

            //     // add borde-raadius
            //     gsap.to(bottomLeftBorder, {
            //         scrollTrigger: {
            //             trigger: ".open-hours-card",
            //             start: "center 60%",
            //             end: "center 50%",
            //             scrub: true,
            //         },
            //         borderRadius: "0 15px 15px 15px",
            //     });
            // });
        }
    }
</script>

<style lang="scss" scoped>

$secondary: #EDEDED;

.summary-section{
    background: $secondary;
    padding: 50px 0;

    .card{
        background: #fff;
        border-radius: 15px;
        padding: 35px;

        display: flex;
        justify-content: space-between;
        gap: 25px;

        max-width: 1200px;
        margin: 0 auto;

        .item{
            flex-grow: 1;
            width: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            text-align: center;

            .item-title{
                font-size: 30px;
                font-weight: 700;
            }

            p, span{
                color: #ACACAC;
            }

            .open-hours-card{
                background: #F6F6F6;
                // border: 3px solid #5500E9;
                border-radius: 0 15px 15px 15px;
                padding: 15px;
                
                width: 100%;
                text-align: left;                

                display: flex;
                flex-direction: column;
                gap: 5px;

                position: relative;

                .days{
                    font-size: 17px;
                }

                .time{
                    font-size: 22px;
                    font-weight: 900;
                    color: #000;
                }

                .top-right-border,
                .bottom-left-border{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 0;

                    border-radius: 0 0 0 0;

                    z-index: 1;
                }

                .top-right-border{
                    border-top: 3px solid #712BEC;
                    border-right: 3px solid #712BEC;
                }

                .bottom-left-border{
                    border-bottom: 3px solid #712BEC;
                    border-left: 3px solid #712BEC;
                }
            }

            .btn{
                width: 100%;
            }
        }

        .divider{
            width: 5px !important; 
            background: linear-gradient(to bottom, rgba(232, 232, 232, 0.4), rgba(232, 232, 232, 1), rgba(232, 232, 232, 0.4));
            flex-shrink: 0;
        }
    }
}

@media only screen and (max-width: 1000px){
    .summary-section{
        .card{
            padding: 20px;
            flex-direction: column;
            max-width: 700px;

            .divider{
                height: 5px !important; 
                width: 100% !important;
                background: linear-gradient(to right, rgba(232, 232, 232, 0.4), rgba(232, 232, 232, 1), rgba(232, 232, 232, 0.4));
                flex-grow: 1;
            }
        }
    }
}

@media only screen and (max-width: 768px){
    .open-hours-card{
        border: 3px solid #5500E9;
        // border-radius: 0 15px 15px 15px;

        .top-right-border{
            display: none;
        }

        .bottom-left-border{
            display: none;
        }
    }
}

</style>