<template>
    <section class="landing-section">
        <div class="content">
            <div class="container">
                <h2 class="header-text">TVORBA VAŠEHO FIT WEBU</h2>

                <video class="gif-animation" muted autoplay loop>
                    <source src="../assets/running-fingers.webm" type="video/webm">
                    Váš prohlížeč nepodporuje přehrávání videí.
                </video>
            </div>
        </div>

        <div class="background-layer">
            <div class="circle circle1"></div>
            <div class="circle circle2"></div>
            <div class="circle circle3"></div>
        </div>

        <div class="scroll-down-icon" @click="this.$parent.scrollToElementWithId('summary-section')">
            <a>
                <i class="fas fa-chevron-down" size="2x" color="#000" />
            </a>
        </div>
    </section>
</template>

<script>
    import { gsap } from "gsap";

    export default {
        mounted(){
            const tl = gsap.timeline();

            setTimeout(() => {
                tl.from(".header-text", { duration: 1, y: -50, opacity: 0 })
                    .from(".gif-animation", { duration: 1, y: -50, opacity: 0 }, "-=0.5")
                    .from(".scroll-down-icon", { duration: 1, y: -50, opacity: 0 }, "+=1");
            }, 500);
        }
    }
</script>

<style lang="scss" scoped>

.landing-section{
    position: relative;
    width: 100%;
    min-height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;


    padding-top: 100px;
    padding-bottom: 100px;

    .content{
        position: relative;
        z-index: 1;
        min-height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        .container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;
            text-align: center;
        }

        .header-text{
            font-size: 77px;
            font-weight: 600;
        }

        .gif-animation{
            // width: 500px !important;
            // height: 370px !important;
            width: 500px !important;
            height: 375px !important;

            border-radius: 20px;
            background: #000;
            position: relative;
        }
    }

    .background-layer{
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: blur(110px);

        .circle{
            position: absolute;
            border-radius: 50%;
        }

        .circle1{
            background: #A749F0;
            width: 20%;
            padding-bottom: 20%;
            top: 20%;
            left: 10%;
        }
        
        .circle2{
            background: #FFC806;
            width: 13%;
            padding-bottom: 13%;
            top: 40%;
            left: 0%;
        }
        
        .circle3{
            background: #387CFF;
            width: 17%;
            padding-bottom: 17%;
            bottom: 20%;
            right: 0%;
        }
    }

    .scroll-down-icon{
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translate(-50%);
        z-index: 1;
        
        animation-name: scroll-down-icon;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        
        i{  
            font-size: 30px;
        }
    }

    @keyframes scroll-down-icon{
        0%{  
            bottom: 15px;
        }
        16%{
            bottom: 25px;
        }
        33%{
            bottom: 5px;
        }
        50%{
            bottom: 15px;
        }
    }
}



@media only screen and (max-width: 1300px){
    .landing-section{
        .content{
            .container{
                gap: 15px;
            }

            .header-text{
                font-size: 50px !important;
            }

            .gif-animation{
                width: 300px !important;
                height: 225px !important;
                border-radius: 20px;
                background: #000;
                position: relative;
            }
        }
    }
}

@media only screen and (max-width: 1100px){
    .landing-section{
        .content{
            // gap: 20px !important;

            // .header-text{
            //     font-size: 60px !important;
            // }

            // .gif-animation{
            //     width: 300px;
            //     height: 300px;
            // }
        }

        .background-layer{
            .circle1{
                width: 30%;
                padding-bottom: 30%;
            }
            
            .circle2{
                width: 17%;
                padding-bottom: 17%;
            }
            
            .circle3{
                background: #387CFF;
                width: 20%;
                padding-bottom: 20%;
            }
        }
    }
}

@media only screen and (max-width: 650px){
    .landing-section{
        // min-height: 100%;
        min-height: calc(var(--vh, 1vh) * 100);

        .content{
            .container{
                gap: 10px !important;
            }

            .header-text{
                font-size: 38px !important;
            }

            .gif-animation{
                width: 100% !important;
                height: auto !important;
                border-radius: 15px;
                margin-top: 10px;
            }
        }

        .background-layer{
            .circle1{
                width: 60%;
                padding-bottom: 60%;
                top: 10%;
                left: -20%;
            }
            
            .circle2{
                width: 35%;
                padding-bottom: 35%;
                top: 40%;
                left: 0;
            }
            
            .circle3{
                width: 35%;
                padding-bottom: 35%;
                bottom: 10%;
                right: 0;
            }
        }
        
        .scroll-down-icon{
            bottom: 20px;
        }
    }
}

</style>