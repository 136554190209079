<template>
    <Header position="absolute" />

    <landing-section />

    <summary-section />

    <section class="wave-background">
        <img src="./assets/wave.svg">
    </section>

    <references-section />

    <our-work-show />

    <motivation-section />

    <timeline-section />

    <contact-section />

    <footer class="footer">
        <div class="container">
            &copy; 2023 FitPixels.
        </div>
    </footer>
</template>

<script>

import Header from "./components/Header";
import LandingSection from "./components/LandingSection";
import SummarySection from "./components/SummarySection";
import ReferencesSection from "./components/ReferencesSection";
import OurWorkShow from "./components/OurWorkShow";
import MotivationSection from "./components/MotivationSection.vue";
import TimelineSection from "./components/TimelineSection";
import ContactSection from "./components/ContactSection";
    
import "vueperslides/dist/vueperslides.css";

export default {
    name: 'App',
    components: { Header, LandingSection, SummarySection, ReferencesSection, OurWorkShow, MotivationSection, TimelineSection, ContactSection },
    methods: {
        scrollToElementWithId(scrollToElementId){
            const summarySection = document.getElementById(scrollToElementId);
            if(summarySection) {
                summarySection.scrollIntoView({ behavior: "smooth" });
            }
        }
    }
}

</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 18px;
}

html{
    font-family: 'Inter', sans-serif;
}

body{
    overflow-x: hidden;
}

p, span{
    color: #ACACAC;
}

.btn{
    background: #616161;
    padding: 15px 30px;
    color: #fff !important;
    font-weight: 600;
    font-size: 20px;
    border-radius: 20px;

    display: flex;
    transition: .3s;
    text-decoration: none;
    justify-content: center;

    border: none;
}

.btn-primary{
    background: #8540E8;
    color: #fff !important;

    &:hover{
        background: darken(#8540E8, 10%);
    }
}

.btn-highlight{
    background: #5500E9;
    color: #fff !important;

    &:hover{
        background: darken(#5500E9, 10%);
    }
}

.btn-secondary{
    background: #fff;
    border: 4px solid #767676;
    font-weight: 700;
    color: #616161 !important;
    padding: 11px 26px;

    &:hover{
        border: 4px solid darken(#5500E9, 10%);
        background: darken(#5500E9, 10%);
        color: #fff !important;
    }
}

.container{
    max-width: 1440px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
}

.wave-background{
    width: 100%;

    img{
        width: 100%;
    }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.footer{
    padding: 20px 0;
    border-top: 2px solid #ACACAC;

    .container{
        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: 600;
        color: #767676;
    }
}

</style>