<template>
    <section class="our-work-show">
        <div class="container">
            <transition name="fade">
                <h1 v-if="!hideSlideshowContent">{{ slideshow[activeSlide].title }}</h1>
            </transition>
            
            <transition name="fade">
                <div class="tags" v-if="!hideSlideshowContent">
                    <div class="tag" v-for="(tag, i) in slideshow[activeSlide].tags" :key="i">{{ tag }}</div>
                </div>
            </transition>

            <div class="slideshow-wrapper">
                <!-- <vueper-slides class="no-shadow" :bullets="false" transition-speed="250"  -->
                <vueper-slides class="no-shadow" bullets-outside transition-speed="250" :slide-ratio="0.5625"
                    @slide="slideChanged($event)">
                    <vueper-slide class="slide"
                        v-for="(slide, i) in slideshow"
                        :key="i"
                        :image="slide.image">
                    </vueper-slide>
                </vueper-slides>
            </div>
        </div>
    </section>
</template>

<script>
    import { VueperSlides, VueperSlide} from "vueperslides";
    import { gsap } from "gsap";
    import ScrollTrigger from "gsap/ScrollTrigger";

    export default {
        components: { VueperSlides, VueperSlide},
        data() {
            return {
                activeSlide: 0,
                hideSlideshowContent: false,
                slideshow: [
                    {
                        title: "Cuty Brýle",
                        tags: ["Design", "WordPress", "Copywriting"],
                        // eslint-disable-next-line no-undef
                        image: require("@/assets/cuty.jpg")
                    },
                    {
                        title: "Člověče nezlob se",
                        tags: ["Design", "Vývoj na míru", "Google Analytics", "Tvorba vzdělávacího obsahu"],
                        // eslint-disable-next-line no-undef
                        image: require("@/assets/clovece.jpg")
                    },
                ]
            }
        },
        mounted(){
            gsap.registerPlugin(ScrollTrigger);

            gsap.to(".tag", {
                scrollTrigger: {
                    trigger: ".tag",
                    start: "top bottom",
                    end: "top 70%",
                    scrub: true,
                    // markers: true
                },
                borderLeftColor: "#712BEC"
            });
        },
        methods: {
            slideChanged(event){
                this.hideSlideshowContent = true;
                this.activeSlide = event["currentSlide"]["index"];

                setTimeout(() => {
                    this.hideSlideshowContent = false;
                }, 250);
            },
        }
    }
</script>

<style lang="scss">

.our-work-show{
    padding: 50px 0;

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    h1{
        font-size: 60px;
        font-weight: 700;
        text-align: center;
    }

    .tags{
        display: flex;
        gap: 15px;
        justify-content: center;
        flex-wrap: wrap;

        .tag{
            background: #EDEDED;
            border-left: 4px solid #712BEC;
            border-radius: 15px;
            padding: 10px 20px;
            flex-wrap: 0;
        }
    }

    .slideshow-wrapper{
        width: 100%;

        .vueperslides__arrow svg{
            display: none;
            stroke-width: 2;
        }

        // .vueperslides__bullet svg {
        //     fill: red;
        // }

        .vueperslides__fixed_height {
            height: 1000px;
        }

        .slide{
            border-radius: 15px;
        }
    }
}

@media only screen and (max-width: 900px){
    .our-work-show{
        h1{
            font-size: 40px !important;
        }

        // .slideshow-wrapper .vueperslides__arrow svg{
        //     font-size: 16px;
        //     // stroke-width: 2;
        // }
    }
}

// @media only screen and (max-width: 700px){
//     .our-work-show{
//         .slideshow-wrapper .vueperslides__arrow svg{
//             font-size: 12px;
//             // stroke-width: 2;
//         }
//     }
// }

</style>